import { NgModule } from '@angular/core';
import { CommonModule, } from '@angular/common';
import { BrowserModule } from '@angular/platform-browser';
import { Routes, RouterModule } from '@angular/router';
import { RedirectComponent } from './redirect.component';

const RedirectRoutes: Routes = [
    {
        path: 'defip',
        component: RedirectComponent
    }
];


@NgModule({
    imports: [
      CommonModule,
      BrowserModule,
      RouterModule.forRoot(RedirectRoutes, {
        useHash: true
      })
    ],
    exports: [
    ],
  })

export class RedirectRoutesModule { }
