import { Component, OnInit, AfterViewInit } from '@angular/core';

@Component({
    selector: 'app-redirect',
    templateUrl: './redirect.component.html',
    styleUrls: ['./redirect.component.scss']
})

export class RedirectComponent implements OnInit, AfterViewInit {
  focus: any;
  focus1: any;

  constructor() { }

  ngOnInit() {}

  ngAfterViewInit() {
    setTimeout(() => {
        window.location.href = 'https://cursos.defip.com.br/cursos-defip';
    }, 3000)
  }
}
